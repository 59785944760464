import { render, staticRenderFns } from "./KitsDetail.vue?vue&type=template&id=b53fab34&scoped=true&"
import script from "./KitsDetail.vue?vue&type=script&lang=js&"
export * from "./KitsDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b53fab34",
  null
  
)

export default component.exports