<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'试剂图片'" />
    <el-image
      style="width: 300px; height: 300px"
      :src="detailData.image"
      :fit="fit"
    ><div slot="error" class="image-slot">
        <i class="el-icon-picture-outline">暂无图片</i>
      </div></el-image>
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'

export default {
  name: 'KitsDetail',
  components: { TJDetailTitle, TJDetailMenu },
  data() {
    return {
      fit: '',
      url: '',
      detailId: '',
      detailData: {},
      dataList: [
        {
          name: '试剂名称',
          value: '',
          width: '33%',
        },
        {
          name: '试剂批号',
          value: '',
          width: '33%',
        },
        {
          name: '检测项目',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: 'UDI',
          value: '',
          width: '33%',
        },
        {
          name: '经销商',
          value: '',
          width: '33%',
        },
        {
          name: '医院',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '生产日期',
          value: '',
          width: '33%',
        },
        {
          name: '有效日期',
          value: '',
          width: '33%',
        },
        {
          name: '开瓶日期',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '最后使用日期',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '已使用人份数',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '机号',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitkitDetail()
  },
  methods: {
    // 试剂详情接口
    gitkitDetail() {
      apiDetail.kitDetail({ kit_id: this.detailId }).then((res) => {
        this.detailData = res.data

        this.dataList[0].value = this.detailData.reagent_name
        this.dataList[1].value = this.detailData.batch
        this.dataList[2].value = this.detailData.testing_name
        this.dataList[3].value = this.detailData.kit_id
        this.dataList[4].value = this.detailData.customer_name
        this.dataList[5].value = this.detailData.hospital_name
        this.dataList[6].value = this.detailData.made_date
        this.dataList[7].value = this.detailData.expire_date
        this.dataList[8].value = this.detailData.open_date
        this.dataList[9].value = this.detailData.last_used_date
        this.dataList[10].value = this.detailData.cnt
        this.dataList[11].value = this.detailData.instrument_id
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
